<template>
  <div class="advantages">
    <div class="wrapper-content">
      <ul class="advantages__list">
        <li class="advantages__item">
          <span class="advantages__icon">
            <img src="./assets/fast.svg" />
          </span>
          <h3 class="advantages__title">Быстро</h3>
          <p class="advantages__desc">
            Получение предложений<br />
            в максимально короткие сроки
          </p>
        </li>
        <li class="advantages__item">
          <span class="advantages__icon">
            <img src="./assets/simple.svg" />
          </span>
          <h3 class="advantages__title">Просто</h3>
          <p class="advantages__desc">
            Нужен только мобильный<br />
            телефон и паспорт
          </p>
        </li>
        <li class="advantages__item">
          <span class="advantages__icon">
            <img src="./assets/online.svg" />
          </span>
          <h3 class="advantages__title">Онлайн</h3>
          <p class="advantages__desc">
            Не выходя из дома,<br />
            в течение 15 минут
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantages",
};
</script>
